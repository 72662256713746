import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Outlet,
} from "react-router-dom";
import { Button, CssBaseline, Typography } from "@mui/material";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LinearProgress, Stack } from "@mui/material";
import { ThemeToggleProvider } from "./ThemeContext"; // Import the ThemeToggleProvider
import { motion } from "framer-motion";
import { store, persistor } from "./state/store/store";
import { HiArrowLongLeft } from "react-icons/hi2";

const pageVariants = {
  initial: {
    opacity: 0,
    y: "100vw",
    //  scale: 0.4,
  },
  in: {
    opacity: 1,
    y: 0,
    // scale: 1,
  },
  out: {
    opacity: 0,
    y: "100vw",
    //  scale: 1.2,
  },
};

const pageTransition = {
  ease: "anticipate",
  duration: 0.5,
};

function withAnimation(Component) {
  return function AnimatedComponent() {
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Component />
      </motion.div>
    );
  };
}

const AppContainer = withAnimation(
  lazy(() => import("./layouts/container/index"))
);
const Dashboard = withAnimation(lazy(() => import("./pages/dashboard/index")));

const Logging = lazy(() => import("./pages/logging/index"));
const Profile = withAnimation(lazy(() => import("./pages/profile/index")));

const JoinOrganisation = lazy(() => import("./pages/authentication/join"));
const ResetPassword = lazy(() => import("./pages/authentication/reset"));
const ForgotPassword = lazy(() => import("./pages/authentication/forgot"));
const Login = withAnimation(lazy(() => import("./pages/authentication/login")));
const Signup = withAnimation(
  lazy(() => import("./pages/authentication/signup"))
);
const NewProject = withAnimation(
  lazy(() => import("./pages/projects/new/index"))
);
const Projects = withAnimation(lazy(() => import("./pages/projects/index")));
const Project = withAnimation(
  lazy(() => import("./pages/projects/manage/index"))
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack sx={{ p: 4, alignItems: "center", textAlign: "center" }}>
          <h6>Soryy, something went wrong.</h6>

          <Button
            color="success"
            startIcon={<HiArrowLongLeft />}
            onClick={() => {
              window.location.href = "https://studio.qflit.com";
            }}
            sx={{ borderRadius: 32, textTransform: "none", mt: 1 }}
          >
            Back home
          </Button>
        </Stack>
      );
    }

    return this.props.children;
  }
}

const LoaderComponent = () => (
  <Stack
    sx={{
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <LinearProgress sx={{ width: "120px", borderRadius: 32 }} />
  </Stack>
);

/**
 *  errorElement={
          <Suspense fallback={<LoaderComponent isRoot={true} />}>
            <ErrorPage />
          </Suspense>
        }
 */

const NotFoundPage = () => {
  return (
    <Stack style={{ textAlign: "center", alignItems: "center", p: 4 }}>
      <h5>404 - Page Not Found</h5>
      <Typography>
        The page you are looking for doesn't exist or has been moved.
      </Typography>
      <Button
        startIcon={<HiArrowLongLeft />}
        color="success"
        onClick={() => {
          window.location.href = "https://studio.qflit.com";
        }}
        sx={{ borderRadius: 32, textTransform: "none", mt: 1 }}
      >
        Back home
      </Button>
    </Stack>
  );
};

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path="/"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <AppContainer />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Dashboard />
              </Suspense>
            }
          />

          <Route
            path="logging"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Logging />
              </Suspense>
            }
          />

          <Route
            path="profile"
            element={
              <Suspense fallback={<LoaderComponent />}>
                <Profile />
              </Suspense>
            }
          />
          <Route path="/projects" element={<Outlet />}>
            <Route
              index
              element={
                <Suspense fallback={<LoaderComponent />}>
                  <Projects />
                </Suspense>
              }
            />
            <Route
              path="new"
              element={
                <Suspense fallback={<LoaderComponent />}>
                  <NewProject />
                </Suspense>
              }
            />
            <Route path=":id" element={<Outlet />}>
              <Route
                index
                element={
                  <Suspense fallback={<LoaderComponent />}>
                    <Project />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="login"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="register"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <Signup />
            </Suspense>
          }
        />
        <Route
          path="join"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <JoinOrganisation />
            </Suspense>
          }
        />
        <Route
          path="forgot"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="reset"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <ResetPassword />
            </Suspense>
          }
        />
      </Route>
    )
  );

  return (
    <ThemeToggleProvider>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading...</p>
            </div>
          }
          persistor={persistor}
        >
          <ErrorBoundary>
            <RouterProvider router={router} />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </ThemeToggleProvider>
  );
};

export default App;
