import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "../../api/api.service";
import userReducer from "../slices/user";
import dialogReducer from "../slices/dialog";
import notificationCountReducer from "../slices/notify";
import paginationReducer from "../slices/pagination";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["pagination"],
};

const rootReducer = combineReducers({
  user: userReducer,
  pagination: paginationReducer,
  dialog: dialogReducer,
  notificationCount: notificationCountReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
