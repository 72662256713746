import {
  Comment01Icon,
  Home01Icon,
  UserMultiple02Icon,
  Analytics02Icon,
  Settings01Icon,
  MoneyBag02Icon,
  WorkUpdateIcon,
  HelpCircleIcon,
  CustomerService01Icon,
  CreditCardPosIcon,
  LabsIcon,
  SettingsError02Icon,
  UserAccountIcon,
  LockKeyIcon,
} from "hugeicons-react";

const mainMenuIconSize = 20;

const menuOptions = [
  {
    section: "Home",
    options: [
      {
        id: "/",
        title: "Dashboard",
        Icon: (props) => (
          <Home01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
      {
        id: "profile",
        title: "Profile",
        Icon: (props) => (
          <UserAccountIcon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
  {
    section: "Build",
    options: [
      {
        id: "projects",
        title: "Projects",
        Icon: (props) => (
          <WorkUpdateIcon color={props.color} size={mainMenuIconSize} />
        ),
      },
      {
        id: "api-keys",
        title: "API Keys",
        Icon: (props) => (
          <LockKeyIcon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
  {
    section: "Account",
    options: [
      {
        id: "billing",
        title: "Billing",
        Icon: (props) => (
          <CreditCardPosIcon color={props.color} size={mainMenuIconSize} />
        ),
      },
      {
        id: "settings",
        title: "Settings",
        Icon: (props) => (
          <Settings01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
  {
    section: "Help",
    options: [
      {
        id: "support",
        title: "Support",
        Icon: (props) => (
          <CustomerService01Icon color={props.color} size={mainMenuIconSize} />
        ),
      },
      {
        id: "troubleshoot",
        title: "Troubleshoot",
        Icon: (props) => (
          <HelpCircleIcon color={props.color} size={mainMenuIconSize} />
        ),
      },
    ],
  },
];

const constants = {
  apiUrl: "https://api.qflit.com",
  notifier: "ws://api.qflit.com",
};
//,
export { constants, menuOptions };
