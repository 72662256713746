// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./auth-header";
import { setUser } from "../state/slices/user";
import { constants } from "../config/constants";
import { updateDialog } from "../state/slices/dialog";

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // const user = api.getState().user;
  let authorization = authHeader();
  const baseQuery = fetchBaseQuery({
    baseUrl: constants.apiUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("authorization", authorization);
    },
  });
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      api.dispatch(setUser({ user: null }));
    } else {
      let msg = null;
      if (result.error?.data?.message) {
        // Handle the case where message is a string
        if (typeof result.error.data.message === "string") {
          msg = result.error.data.message;
        }
        // Handle the case where message is an array
        else if (Array.isArray(result.error.data.message)) {
          msg = result.error.data.message.join(" ");
        }
      }

      if (!msg) {
        msg = "Sorry, there was an error completing your request.";
      }

      api.dispatch(updateDialog({ open: true, msg }));
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ["User", "Contact", "Note", "Assistant"],
  endpoints: () => ({}),
});
